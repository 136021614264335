import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import "./Users.css";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { DataGrid } from "@mui/x-data-grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
const usersPerPage = 5;

// Alert for MUI
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1100,
  bgcolor: "background.paper",
  border: "2px solid lightBlue",
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "username", headerName: "Username", width: 160 },
  { field: "name", headerName: "Name", width: 160 },
  { field: "surname", headerName: "Surname", width: 160 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "telephone", headerName: "Telephone", width: 150 },
];

// Displaying the Users to the admin and their information
function Users() {
  let navigate = useNavigate();

  const [userList, setUserList] = useState([]);
  const [notApprovedList, setNotApprovedList] = useState([]);
  const [approvedList, setApprovedList] = useState([]);

  useEffect(() => {
    const head = {
      headers: {
        accessToken: localStorage.getItem("accessToken"),
      },
    };

    axios.get("https://backgem.tfdatamaster.com/auth", head).then((res) => {
      setUserList(res.data);
    });

    axios.get("https://backgem.tfdatamaster.com/auth/approve", head).then((res) => {
      setNotApprovedList(res.data);
    });
  }, [approvedList]);

  const handleApprove = () => {
    if (approvedList.length !== 0) {
      const head = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };

      axios
        .put("https://backgem.tfdatamaster.com/auth/approve", approvedList, head)
        .then((res) => {
          if (res.data.error) {
            setErrorMessage(res.data.error);
            handleClickAlertError();
          } else {
            handleClickAlert();
          }
        });

      setApprovedList([]);
      setUserList([]);
    }
    setOpen(false);
  };

  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 9;
  const visitedPages = pageNumber * usersPerPage;
  const pageCount = Math.ceil(userList.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const displayUsers = userList
    .slice(visitedPages, visitedPages + usersPerPage)
    .map((value, key) => {
      return (
        <div className="adminusercard" key={key}>
          <div className="adminuserinfos">
            <div className="adminuserimage"></div>
            <div className="adminuserinfo">
              <div>
                <p className="adminusername">
                  {value.approved ? (
                    <CheckCircleIcon style={{ color: "teal" }} />
                  ) : (
                    <NotInterestedIcon style={{ color: "teal" }} />
                  )}{" "}
                  {value.username}
                </p>

                <p className="adminuserfunction">
                  {value.location}, {value.country}
                </p>
              </div>
            </div>
          </div>
          <button
            className="adminuserrequest"
            type="button"
            onClick={() => {
              navigate(`/profile/${value.id}`);
            }}
          >
            See More
          </button>
        </div>
      );
    });

  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleClickAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlertR = (event, reason) => {
    setOpenAlert(false);
    window.location.reload();
  };

  const [openAlertError, setOpenAlertError] = React.useState(false);
  const handleClickAlertError = () => {
    setOpenAlertError(true);
  };
  const handleCloseAlertError = (event, reason) => {
    setOpenAlertError(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="background-image-container">
      <div className="container">
        <div className="User-T">
          <header className="title" style={{ color: 'white', fontSize: "30px", fontWeight: "500px" }}>Users</header>
        </div>
      </div>
      <div className="container">
        <header className="header-text" style={{ color: 'white', fontSize: "30px", fontWeight: "500px" }}>Click on a user profile for more details</header>
      </div>

      <div className="container" >
        {/* <div style={{ marginBottom: "40px", justifyContent: "center" }}>
          <button className="custom-button" style={{ borderRadius: "20px", padding: "10px", backgroundColor: "white", color: "black" }} onClick={handleOpen}>
            Check Applications
          </button>
        </div> */}
        <div className="userscontainer">{displayUsers}</div>
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationButtons"}
          previousLinkClassName={"previousButton"}
          nextLinkClassName={"nextButton"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="custom-modal" style={style}>
            <div className="header">
              <headerito>Users Awaiting Confirmation</headerito>
            </div>
            <div style={{ height: 369, width: 1100 }}>
              <DataGrid
                className="custom-data-grid"
                rows={notApprovedList}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onSelectionModelChange={(data) => {
                  setApprovedList(data);
                }}
              />
            </div>
            <button className="custom-button" onClick={handleApprove}>
              Approve Selected
            </button>
          </Box>
        </Modal>

        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlertR}
        >
          <Alert
            onClose={handleCloseAlertR}
            severity="success"
            className="custom-alert"
          >
            Users successfully approved!
          </Alert>
        </Snackbar>

        <Snackbar
          open={openAlertError}
          autoHideDuration={6000}
          onClose={handleCloseAlertError}
        >
          <Alert
            onClose={handleCloseAlertError}
            severity="warning"
            className="custom-alert"
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Users;

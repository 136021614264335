import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

// Logout window implemented with a dialog box
function Logout() {
  let navigate = useNavigate();
  const { setAuthState } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    localStorage.removeItem("accessToken");
    setAuthState({
      username: "",
      id: 0,
      status: false,
    });
    setOpen(false);
    navigate("/");
  };

  return (
    <div>
      <div className="buttonr" style={{cursor:"pointer"}} onClick={handleClickOpen}>
        <span>Logout</span>
      </div>

      <Dialog open={open} onClose={handleClose}>
  <DialogTitle>Are you sure you want to sign out?</DialogTitle>
  <DialogActions>
    <button
      style={{
        backgroundColor: "#1E262D",
        color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        marginRight: "10px",
        border: "none",
        cursor: "pointer",
      }}
      onClick={handleClose}
    >
      Cancel
    </button>
    <button
      style={{
        backgroundColor: "#1E262D",
        color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      onClick={handleConfirm}
      autoFocus
    >
      Confirm
    </button>
  </DialogActions>
</Dialog>

    </div>
  );
}

export default Logout;

import React from "react";
import "./Title.css";

function Title(props) {
  return (
    <div>
      <div className="custom-box">
        <h4 className="custom-text">{props.title}</h4>
      </div>
    </div>
  );
}

export default Title;

import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../components/AuthContext";
import DisplayCoordinates from "../components/Maps/DisplayCoordinates";
import ReactPaginate from "react-paginate";
import ConvertDMS from "../components/Maps/ConvertDMS";
import "./Profile.css"; // Add your CSS class definitions here
import Rating from "@mui/material/Rating";

// Material UI components
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import PointOfSaleSharpIcon from "@mui/icons-material/PointOfSaleSharp";
import ShoppingBagSharpIcon from "@mui/icons-material/ShoppingBagSharp";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";

// Modals style (You can add your CSS class definitions for styling the modal here)
// Define the style object for your modal

function Profile() {
  let navigate = useNavigate();
  let { id } = useParams();
  const [userInfo, setUserInfo] = useState({});
  const [myItems, setMyItems] = useState([]);

  const [coordinates, setCoordinates] = useState([]);

  const { authState } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get(`https://backgem.tfdatamaster.com/auth/fetchyall/${id}`)
      .then((response) => {
        if (response.data.message) {
          console.log(response.data.message);
          navigate("/");
        } else {
          setUserInfo(response.data);

          if (response.data.latitudeLongitude !== null) {
            setCoordinates(response.data.latitudeLongitude.coordinates);
          }

          axios
            .get(`https://backgem.tfdatamaster.com/items/fetchByUser/${id}`)
            .then((res) => {
              const available = res.data.filter((value) => {
                return value.state === "AVAILABLE";
              });
              setMyItems(available);
            })
            .catch((error) => {
              console.log(error);
              navigate("/");
            });
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/");
      });
  }, [id, navigate]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleApprove = () => {
    if (authState.username === "admin") {
      var approvedl = [];
      approvedl.push(userInfo.id);
      const head = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };
      axios
        .put("https://backgem.tfdatamaster.com/auth/approve", approvedl, head)
        .then((res) => {
          if (res.data.error) {
            alert("There was an error!");
          }
        });

      // Change to approved so the changes are shown in the ui as well dynamically
      setUserInfo({ ...userInfo, approved: true });
    }
    handleClose();
  };

  // Pagination Information
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 6;
  const visitedPages = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(myItems.length / itemsPerPage);

  // Displaying the items of this particular page
  const displayItems = myItems
    .slice(visitedPages, visitedPages + itemsPerPage)
    .map((value, key) => {
      return (
        <div
          className="item" // Use your CSS class names here for styling
          onClick={() => {
            navigate(`/item/${value.id}`);
          }}
        >
          <div className="name">{value.name} </div>
          <div className="body">
            <img className="lando_image" src={value.coverPhoto} alt="product" />
          </div>
          <div
            className="footer .background-primary {
"
          >
            <div className="footer">
              <div>
                {value.location}, {value.country}
              </div>
              <div style={{ color: "#14b6e3" }}>
                {value.currently} € &nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  // 
  return (
    <>
      <div>
        <div className="Procontainer">
          <div class="profile-photo"></div>
          {/*  */}
          <div className="profileName">
            {`${userInfo.name} ${userInfo.surname}`}
          </div>

          <div className="grid-container">
            <div className="searchInputs">
              <div className="nameapproved">
                {userInfo.username}
                {userInfo.approved ? (
                  <CheckCircleIcon
                    style={{
                      color: "lightblue",
                      fontSize: "16px",
                    }}
                  />
                ) : (
                  <NotInterestedIcon
                    style={
                      {
                        /* Add your CSS styles for the NotInterestedIcon */
                      }
                    }
                  />
                )}
              </div>
            </div>
            <br />
            <div className="searchInputs">
              <div className="location-info">
                <LocationOnIcon
                  style={{ fontSize: "24px", verticalAlign: "middle" }}
                />
                <span className="location-text">
                  {userInfo.location}, {userInfo.country}
                </span>
              </div>
            </div>

            <div className="searchInputs">
              <div className="seller-info">
                <PointOfSaleSharpIcon
                  style={{ fontSize: "24px", verticalAlign: "middle" }}
                />
                {userInfo.saleCount ? (
                  <>
                    &nbsp;Seller Rating: {userInfo.sellerRating} &nbsp; Average:
                    &nbsp;
                    <div className="rating">
                      <Rating
                        name="read-only"
                        value={userInfo.sellerRating / userInfo.saleCount}
                        readOnly
                        precision={0.5}
                      />
                    </div>
                  </>
                ) : (
                  <>&nbsp;No Sales Yet</>
                )}
              </div>
            </div>

            <div className="searchInputs">
              <div className="bidder-info">
                <ShoppingBagSharpIcon
                  style={{ fontSize: "24px", verticalAlign: "middle" }}
                />
                {userInfo.buyCount ? (
                  <>
                    &nbsp;Bidder Rating: {userInfo.bidderRating} &nbsp; Average:
                    &nbsp;
                    <div className="rating">
                      <Rating
                        name="read-only"
                        value={userInfo.bidderRating / userInfo.buyCount}
                        readOnly
                        precision={0.5}
                      />
                    </div>
                  </>
                ) : (
                  <>&nbsp;No Purchases Yet</>
                )}
              </div>
            </div>
          </div>
          {authState.username === "admin" && (
            <>
              <div className="searchInputs">
                <div className="info-item">
                  <EmailIcon />
                  <span>{userInfo.email}</span>
                </div>
                <div className="info-item">
                  <PhoneIphoneIcon />
                  <span>{userInfo.telephone}</span>
                </div>
              </div>

              {Object.keys(coordinates).length > 0 && (
                <div>
                  <div className="username-location">
                    <h6>{userInfo.username}'s Location on the Map</h6>
                  </div>

                  <DisplayCoordinates
                    lat={coordinates[0]}
                    lng={coordinates[1]}
                  />
                  {ConvertDMS(coordinates[0], coordinates[1])}
                </div>
              )}
              {!userInfo.approved && (
                <>
                  <div>
                    <Typography variant="h6" component="div">
                      {userInfo.username} hasn't been approved yet.
                    </Typography>
                  </div>
                  <button className="buttonito" onClick={handleOpen}>
                    Approve
                  </button>
                </>
              )}
            </>
          )}
        </div>

        <Divider
          sx={
            {
              /* Add your CSS styles for the divider */
            }
          }
        />
        <br />
        {myItems.length !== 0 && (
          <>
            <div className="auction-title">{userInfo.username}'s Auctions</div>
            <br />
            <div className="itemincontainerr">

            </div>

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationButtons"}
              previousLinkClassName={"previousButton"}
              nextLinkClassName={"nextButton"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal-content">
            <Typography variant="h5" component="h2">
              Are you sure you want to approve {userInfo.username}?
            </Typography>
            <Typography variant="h6" component="div" style={{color : "red"}}>
              You can't reverse this action
            </Typography>
            <button className="buttonitoReverse" onClick={handleClose} style={{backgroundColor : "red" , color: "white" }}>
              Cancel
            </button>
            <button className="buttonito" onClick={handleApprove} autoFocus>
              Confirm
            </button>
          </div>
        </Modal>

      </div>
    </>
  );
}

export default Profile;

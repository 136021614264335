import React, { useState } from "react";
import "./PhotoCarousel.css";

const style = {
  position: "flex",
  bgcolor: "rgba(0, 0, 0, 0.551)",
  borderRadius: 2,
  boxShadow: 12,
};

function DisplayPhotos(props) {
  const [currImg, setCurrImg] = useState(0);

  const goLeft = () => {
    if (currImg > 0) {
      setCurrImg(currImg - 1);
      if (props.setSelectedImage) {
        props.setSelectedImage(currImg - 1);
      }
    }
  };

  const goRight = () => {
    if (currImg < props.images.length - 1) {
      setCurrImg(currImg + 1);
      if (props.setSelectedImage) {
        props.setSelectedImage(currImg + 1);
      }
    }
  };

  return (
    <>
      {Object.keys(props.images).length > 0 ? (
        <div className="carousel" style={style}>
          <div
            className="carouselInner"
            style={{ backgroundImage: `url(${props.images[currImg].url})` }}
          >
            <div className="left" onClick={goLeft}>
              {currImg > 0 && (
                <span
                  className="arrow-back"
                  style={{ fontSize: 30, color: "black" }}
                >
                  {"<"}
                </span>
              )}
            </div>
            <div className="center">
              <div className="right" onClick={goRight}>
                {currImg < props.images.length - 1 && (
                  <span
                    className="arrow-forward"
                    style={{ fontSize: 30, color: "black" }}
                  >
                    {">"}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <img
          className="lando_image"
          alt="cover"
          src={"https://backgem.tfdatamaster.com/images/placeholder.png"}
        />
      )}
    </>
  );
}

export default DisplayPhotos;

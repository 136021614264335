import React, { useEffect, useState } from "react";
import "./Auctions.css";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bgImg from "../../assets/gem-item-cover.jpg"; // Already imported
import FilterCategories from "./FilterCategories";

function Auctions() {
  const [itemList, setItemList] = useState([]);
  const [complete, setComplete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  let navigate = useNavigate();

  useEffect(() => {
    setItemList([]);
    setComplete(false);

    if (Object.keys(selectedCategory).length <= 0) {
      axios.get(`https://backgem.tfdatamaster.com/items`).then((res) => {
        setItemList(res.data);
        setComplete(true);
      });
    } else {
      axios
        .get(`https://backgem.tfdatamaster.com/items/categories/${selectedCategory.id}`)
        .then((res) => {
          setItemList(res.data);
          setComplete(true);
        });
    }
  }, [selectedCategory]);

  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  const handleChangeRows = (event) => {
    setItemsPerPage(event.target.value);
  };

  const visitedPages = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(itemList.length / itemsPerPage);

  const displayItems = itemList
    .slice(visitedPages, visitedPages + itemsPerPage)
    .map((value, key) => (
      <div
        className="Auctioniitem"
        key={key}
        onClick={() => {
          navigate(`/item/${value.id}`);
        }}
      >
        <div className="Auction-body">
          <img
            className="lando_image"
            src={value.coverPhoto}
            alt="coverphoto"
          />
        </div>
        <div className="name">{value.name}</div>
      </div>
    ));

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="auctions-container">
      {/* Background image applied via CSS */}
      <FilterCategories />

      <div className="pauction" style={{borderRadius : "30px"}}>
        {itemList.length === 0 && complete && (
          <>
            <div className="no-results">
              <h4>No auctions found</h4>
            </div>
            <img
              src="https://indususedcars.com/assets/theme/images/no_result_found.png"
              alt="coverphoto"
            />
            <p className="header-text">
              Unfortunately, there are no current listings for{" "}
              {selectedCategory.name}
            </p>
          </>
        )}

        {itemList.length === 0 && !complete && (
          <>
            <div className="loading">
              <h4>Loading</h4>
            </div>
          </>
        )}

        {itemList.length !== 0 && (
          <>
            <div className="grid-itemicontainerr">{displayItems}</div>
            <div className="pagenavigation">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Auctions;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./Landing.css";
import { CssBaseline } from "@mui/material";
import Slider from "../Searching/Slider";
import About from "./About";
import Card from "./Card";
import Card04 from "./Card04";
import Card05 from "./Card05";
import Navbar from "../Navbar";

function Landing() {
  let navigate = useNavigate();

  const goToAuctions = () => {
    navigate("/auctions");
  };

  const goToLogin = () => {
    navigate("/login");
  };

  const kind = [
    {
      title: "Sell",
      description: "Selling with us is rather easy...",
      imageUrl: "https://backgem.tfdatamaster.com/images/kobuR.jpg",
      time: 1500,
    },
    {
      title: "Buy",
      description: "In our auctions, items are bid on and sold...",
      imageUrl: "https://backgem.tfdatamaster.com/images/saadR.jpg",
      time: 1500,
    },
  ];

  return (
    <div className="landing-container">
      <CssBaseline />
      <Navbar />
      <About />
      <br />
      <div className="slider2fr"></div>
      <Slider />
      <br />
      <div style={{ display: "flex", alignItems: "center", gap: "40px", justifyContent: "center" }}>
        <Card04 />
        <Card05 />
      </div>
      <br />
      <br />
      <div className="slider2f"></div>
      <Card />
      <div style={{ marginTop: "40px" }}>
        <div className="banner-container">
          {/* footer Section */}
          <footer className="footer">
            <div className="footer-content">
              <div className="footer-content">
                <div>
                  <h2>Contact Us</h2>
                  <p>Kushan Seniviratne</p>
                  <p>Email: info@company.com</p>
                  <p>Phone: +94-715809991</p>
                </div>
                <div className="footer-links">
                  <a href="#"><h2>About Us</h2></a>
                  <a href="#"><h2>Services</h2></a>
                  <a href="#"><h2>Privacy Policy</h2></a>
                  <a href="#"><h2>Terms of Use</h2></a>
                  <p className="footer-copy">© 2024 Your Company. All Rights Reserved.</p>
                </div>
              </div>

              
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Landing;

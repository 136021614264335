import * as React from "react";
import axios from "axios";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ReplyIcon from "@mui/icons-material/Reply";
import Modal from "@mui/material/Modal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./MessageCard.css";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// Styling for the box
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: 6,
  boxShadow: 24,
  p: 4,
};

export default function MessageCard(props) {
  // For the alert
  const [openAlert, setOpenAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleClickAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = (event, reason) => {
    setOpenAlert(false);
  };

  // for the error alert
  const [openAlertError, setOpenAlertError] = React.useState(false);

  const handleClickAlertError = () => {
    setOpenAlertError(true);
  };

  const handleCloseAlertError = (event, reason) => {
    setOpenAlertError(false);
  };

  // for sending a message
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setTitulo("");
    setMessagio("");
    setOpen(false);
  };

  const [titulo, setTitulo] = React.useState("");
  const [messagio, setMessagio] = React.useState("");

  const handleTextFieldTitle = (data) => {
    setTitulo(data.target.value);
  };

  const handleTextFieldBody = (data) => {
    setMessagio(data.target.value);
  };

  const messageSend = () => {
    if (titulo === "") {
      setErrorMessage("Cannot send mail with an empty title.");
      handleClickAlertError();
    } else if (titulo.length > 100) {
      setErrorMessage("Title has to be max 100 characters long.");
      handleClickAlertError();
    } else if (messagio === "") {
      setErrorMessage("Cannot send mail with an empty message.");
      handleClickAlertError();
    } else if (messagio.length > 4000) {
      setErrorMessage("The message you're trying to send is too large.");
      handleClickAlertError();
    } else {
      const body = {
        recipientId: props.message.senderId,
        MailId: props.message.MailId,
        title: titulo,
        body: messagio,
      };

      const head = {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      };
      axios
        .post(
          `https://backgem.tfdatamaster.com/mail/message/${props.message.recipientId}`,
          body,
          head
        )
        .then((res) => {
          if (res.data.error) {
            alert(res.data.error);
          }
        });

      handleClickAlert();
      handleClose();
    }
  };

  return (
    <div>
      <div className="sentmailclass">
        <div class="comment-container">
          <br />{" "}
          <div class="user">
            <div class="user-pic">
              <svg
                fill="none"
                viewBox="0 0 24 24"
                height="20"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linejoin="round"
                  fill="#707277"
                  stroke-linecap="round"
                  stroke-width="2"
                  stroke="#707277"
                  d="M6.57757 15.4816C5.1628 16.324 1.45336 18.0441 3.71266 20.1966C4.81631 21.248 6.04549 22 7.59087 22H16.4091C17.9545 22 19.1837 21.248 20.2873 20.1966C22.5466 18.0441 18.8372 16.324 17.4224 15.4816C14.1048 13.5061 9.89519 13.5061 6.57757 15.4816Z"
                ></path>
                <path
                  stroke-width="2"
                  fill="#707277"
                  stroke="#707277"
                  d="M16.5 6.5C16.5 8.98528 14.4853 11 12 11C9.51472 11 7.5 8.98528 7.5 6.5C7.5 4.01472 9.51472 2 12 2C14.4853 2 16.5 4.01472 16.5 6.5Z"
                ></path>
              </svg>
            </div>
            <div class="user-info">
              <span>{props.message.title}</span>
              <span>
                {/* {value.recipientName} {value.recipientSurname} */}
              </span>
              <p>{new Date(props.message.timeSent).toLocaleString("en-GB")}</p>
            </div>
          </div>
          {props.type === "outbox" ? (
            <Typography
              sx={{ fontSize: 12 }}
              color="text.secondary"
              gutterBottom
            >
              Sent to: {props.message.recipientName}{" "}
              {props.message.recipientSurname}
            </Typography>
          ) : (
            <Typography
              sx={{ fontSize: 12 }}
              color="text.secondary"
              gutterBottom
            >
              {props.message.senderName} {props.message.senderSurname}
            </Typography>
          )}{" "}
          <div>{props.message.body}</div>
          {props.type === "inbox" && (
            <CardActions>
              <Button
                size="small"
                style={{ color: "teal" }}
                onClick={() => {
                  handleOpen();
                }}
              >
                <ReplyIcon />
                &nbsp;Reply
              </Button>
            </CardActions>
          )}
        </div>
      </div>
      <div>
        <div style={{ marginTop: 9, marginLeft: 5 }}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="messagecardform">
              <div class="Messagecard">
                <header class="modal-container-header">
                  <span class="modal-container-title">
                    <svg
                      aria-hidden="true"
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M14 9V4H5v16h6.056c.328.417.724.785 1.18 1.085l1.39.915H3.993A.993.993 0 0 1 3 21.008V2.992C3 2.455 3.449 2 4.002 2h10.995L21 8v1h-7zm-2 2h9v5.949c0 .99-.501 1.916-1.336 2.465L16.5 21.498l-3.164-2.084A2.953 2.953 0 0 1 12 16.95V11zm2 5.949c0 .316.162.614.436.795l2.064 1.36 2.064-1.36a.954.954 0 0 0 .436-.795V13h-5v3.949z"
                        fill="currentColor"
                      ></path>
                    </svg>
                    Replying to {props.message.name} {props.message.surname}{" "}
                  </span>
                  <button class="icon-button" onClick={handleClose}>
                    <svg
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M0 0h24v24H0z" fill="none"></path>
                      <path
                        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </header>

                <form class="Messageform" action="#" method="post">
                  <div class="Messagegroup">
                    <input
                      type="text"
                      onChange={handleTextFieldTitle}
                      s
                      id="outlined-multiline-static"
                      name="Messagetitle"
                      rows={1}
                      placeholder=""
                      required
                    ></input>
                    <label for="name">Title</label>
                  </div>

                  <div class="Messagegroup">
                    <textarea
                      id="outlined-multiline-static"
                      onChange={handleTextFieldBody}
                      name="comment"
                      placeholder=""
                      rows="5"
                      required
                    ></textarea>
                    <label for="comment">Message</label>
                  </div>
                  <button
                    className="buttonitoMail"
                    onClick={() => {
                      messageSend();
                    }}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </Modal>

          {/* message alert */}
          <Snackbar
            open={openAlert}
            autoHideDuration={6000}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity="success"
              sx={{ width: "100%" }}
            >
              Your message has been sent !
            </Alert>
          </Snackbar>

          {/* error message alert */}
          <Snackbar
            open={openAlertError}
            autoHideDuration={6000}
            onClose={handleCloseAlertError}
          >
            <Alert
              onClose={handleCloseAlertError}
              severity="warning"
              sx={{ width: "100%" }}
            >
              {errorMessage}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
}

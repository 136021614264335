import React from "react";
import "./Detail.css";

function Detail(props) {
  return (
    <div>
      <div className="custom-box">
        <h5 className="custom-text">{props.text}</h5>
      </div>
    </div>
  );
}

export default Detail;

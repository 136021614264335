import React from 'react'

function NotAllowed() {
  return (
    <div>
      <img alt='Not Allowed' src='https://cdni.iconscout.com/illustration/premium/thumb/error-403-4190274-3468591.png' />
    </div>
  )
}

export default NotAllowed

import React, { useEffect, useState } from "react";
import Registration from "../components/Login/Registration";
import LoginForm from "../components/Login/LoginForm";
import StarrySky from "./StarrySky"; 
import "./Login.css";

function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showRegistration, setShowRegistration] = useState(false);

  return (
    <div className="reg">

    <div className="ilogin-tcontainer">
      {showRegistration ? (
        <Registration />
      ) : (
        <div>

          <LoginForm />
          <div className="ptag">
            <p onClick={() => setShowRegistration(true)}>Don't have an account? Register</p>
          </div>
        </div>
      )}
      <div className="ptag">
        {showRegistration ? (
          <p onClick={() => setShowRegistration(false)}>Already have an account? Login</p>
        ) : null}
      </div>
      </div>
      

    </div>
  );
}

export default Login;

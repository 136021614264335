import React, { useState, useEffect } from "react";
import "./About.scss";
import gems from "../../assets/gem.jpg";
import "animate.css";

function About() {
  const [text, setText] = useState(""); // Text state for typing effect
  const fullText = "Welcome to the King of Asia Gem Application!"; // Full text

  useEffect(() => {
    let currentIndex = 0;
    let isMounted = true;

    const typeText = () => {
      if (isMounted && currentIndex <= fullText.length) {
        setText(fullText.substring(0, currentIndex)); // Use substring to ensure no characters are missed
        currentIndex++;
      } else {
        clearInterval(typingInterval); // Stop the interval when typing is done
      }
    };

    const typingInterval = setInterval(typeText, 100); // Adjust typing speed here

    // Cleanup interval on component unmount
    return () => {
      isMounted = false;
      clearInterval(typingInterval);
    };
  }, [fullText]); // Dependency array includes fullText

  return (
    <div className="containeruy">
      <div className="container-inner">
        <div className="main-content">
          <div className="main-headings" id="main-headings-js">
            <div className="heading" >
              <h3 id="heading1" style={{ fontWeight: "900" }}>Welcome to</h3>

              <br />
              <h1 id="heading3">King of Asia</h1>
            </div>
            <br />
            <div className="short-bio">
              <div
                className="typing-text-container"
                style={{
                  marginLeft: "-2%",
                  width: "80%",
                  marginTop: "90px",
                  fontWeight: "800",
                  fontSize: "1.8rem",
                  lineHeight: "1.8",
                  letterSpacing: "0.05em",
                  color: "white",
                  textShadow: "1px 1px 3px rgba(0,0,0,0.3)",
                  fontFamily: "'Montserrat', sans-serif",
                }}
              >
                <span className="typing-text">{text}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="image-wrapper">
          <div className="image-container">
            <img className="image" src={gems} alt="Gems" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;

import React from "react";
import "./Search.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ApplyFilters from "./ApplyFilters";
import Header from "../Typography/Header";
import "./FilterCategories.css";

// MUI components
import { Typography } from "@mui/material";

import ClearAllIcon from "@mui/icons-material/ClearAll";


function FilterCategories() {
  const [finalList, setFinalList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [wordText, setWordText] = useState("");
  const [wordBar, setWordBar] = useState("");
  const [complete, setComplete] = useState(false);

  // for the price range
  const [superMax, setSuperMax] = useState(100);
  const [priceRange, setPriceRange] = React.useState([0, superMax]);
  const [location, setLocation] = React.useState("");
  const [country, setCountry] = React.useState([]);

  const [selectedFilters, setSelectedFilters] = React.useState([]);

  const [selectedCategory, setSelectedCategory] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    // if no category selected bring everything
    if (Object.keys(selectedCategory).length <= 0) {
      axios.get(`https://backgem.tfdatamaster.com/items`).then((res) => {
        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].currently > superMax) {
            setSuperMax(res.data[i].currently);
          }
        }
        setItemList(res.data);
        setFinalList(res.data);
        setComplete(true);
      });
    } else {
      setWordText("");
      setSelectedFilters([]);
      setLocation("");
      setCountry("");
      setPriceRange([0, superMax]);
      axios
        .get(`https://backgem.tfdatamaster.com/items/categories/${selectedCategory.id}`)
        .then((res) => {
          setItemList(res.data);
          setFinalList(res.data);
          setComplete(true);
          for (var i = 0; i < res.data.length; i++) {
            if (res.data[i].currently > superMax) {
              setSuperMax(res.data[i].currently);
            }
          }
        });
    }

    // eslint-disable-next-line
  }, [selectedCategory]);

  useEffect(() => {
    var newFilteredData = finalList.filter((value) => {
      return (
        value.currently >= priceRange[0] && value.currently <= priceRange[1]
      );
    });
    if (location !== "") {
      newFilteredData = newFilteredData.filter((value) => {
        return value.location === location;
      });
    }
    if (Object.keys(country).length > 0) {
      newFilteredData = newFilteredData.filter((value) => {
        return value.country === country;
      });
    }

    setFinalList(newFilteredData);

    // eslint-disable-next-line
  }, [priceRange, location, country]);

  const filterSearch = (event) => {
    const searchText = event.target.value;
    setWordBar(searchText);
    const newFilteredData = finalList.filter((value) => {
      return (
        value.name.toLowerCase().includes(searchText.toLowerCase()) ||
        value.description.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    if (searchText === "") {
      setFilteredList([]);
    } else {
      setFilteredList(newFilteredData);
    }
  };

  const finalSearch = (e) => {
    if (e.key === "Enter") {
      setWordText(wordBar);
      setFinalList(filteredList);
      clearPressed();
    }
  };

  const clearPressed = () => {
    setFilteredList([]);
    setWordBar("");
  };

  const clearFilters = () => {
    setFinalList(itemList);
    setWordText("");
    setSelectedFilters([]);
    setLocation("");
    setCountry("");
    setPriceRange([0, superMax]);
    setSelectedCategory({});
  };

  // Pagination Information
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(9);

  // const itemsPerPage = 9;
  const visitedPages = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(finalList.length / itemsPerPage);

  // Displaying the items of this particular page
  const displayItems = finalList
    .slice(visitedPages, visitedPages + itemsPerPage)
    .map((value, key) => {
      return (
        <div
          className="item"
          onClick={() => {
            navigate(`/item/${value.id}`);
          }}
        >
          <div className="name">{value.name} </div>
          <div className="body">
            <img className="lando_image" alt="cover" src={value.coverPhoto} />
          </div>
          <div className="footer gradient-custom">
            <div>
              {" "}
              {value.location}, {value.country}
            </div>
            <div style={{ color: "#14b6e3" }}>
              {" "}
              {value.currently} € &nbsp;&nbsp;
            </div>
          </div>
        </div>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleChangeRows = (event) => {
    setItemsPerPage(event.target.value);
  };

  return (
    <>
      <div className="search">
        <br />
        <main></main>
        <div className="searchInputs">
          <form id="masthead-search">
            <input
              type="search"
              name="s"
              aria-labelledby="search-label"
              placeholder="Search&hellip;"
              className="draw"
              style={{
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                marginRight: '10px',
                backgroundColor: '#f3f3f4', 

              }}
              value={wordBar}
              onChange={filterSearch}
              onKeyDown={finalSearch}
            />

            {filteredList.length !== 0 && (
              <div className="searchResult">
                {filteredList.map((value, key) => (
                  <a
                    className="searchItem"
                    key={key}
                    onClick={() => {
                      navigate(`/item/${value.id}`);
                    }}
                  >
                    <p>
                      {value.name} {value.currently} €{" "}
                    </p>
                  </a>
                ))}
              </div>
            )}
          
              <ApplyFilters
                max={superMax}
                setPriceRange={setPriceRange}
                setSelectedFilters={setSelectedFilters}
                setLocation={setLocation}
                setCountry={setCountry}
              />
          </form>
        </div>
        
      </div>

      <div className="search">
        {finalList.length === 0 && complete && (
          <>
            <div
              className="scontainer"
              style={{
                marginTop: 20,
              }}
            >
              <Typography sx={{ fontFamily: "Futura" }} variant="h4">
                no auctions found
              </Typography>
            </div>
            <img
              alt="nothing"
              src="https://indususedcars.com/assets/theme/images/no_result_found.png"
            />
            <Header text="Clear your search criteria and broaden your search" />
          </>
        )}

        {finalList.length === 0 && !complete && (
          <>
            <div
              className="secontainer"
              style={{
                marginTop: 10,
              }}
            >
              <Typography sx={{ fontFamily: "Futura" }} variant="h4">
                Loading
              </Typography>
            </div>
          </>
        )}

        {/* {finalList.length !== 0 && (
          <>
            <div className="grid-itemicontainer">
              <div
                className="rgrid-itemcontainer"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                }}
              >
                {displayItems}
              </div>
            </div>{" "}
            <br />
            <div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />{" "}
            </div>
            <br />
            <div className="navi">
              <Detail
                text={
                  <div>
                    <Box sx={{ minWidth: 100 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Items/Page
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={itemsPerPage}
                          label="items"
                          onChange={handleChangeRows}
                          variant="standard"
                        >
                          <MenuItem value={9}>9</MenuItem>
                          <MenuItem value={18}>18</MenuItem>
                          <MenuItem value={27}>27</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>
                }
              />
            </div>
          </>
        )} */}
        <div className="searchInputs">
          {Object.keys(selectedCategory).length > 0 && (
            <Header text={selectedCategory.name} />
          )}

          {Object.keys(selectedFilters).length > 0 && location !== "" && (
            <Header text={location} />
          )}
          {Object.keys(selectedFilters).length > 0 &&
            Object.keys(country).length > 0 && <Header text={country} />}

          {Object.keys(selectedFilters).length > 0 &&
            selectedFilters[3] !== "" && (
              <Header
                text={`Price Range: ${priceRange[0]}$ - ${priceRange[1]}$`}
              />
            )}

          {Object.keys(wordText).length > 0 && <Header text={wordText} />}

          {(Object.keys(selectedFilters).length > 0 ||
            Object.keys(selectedCategory).length > 0 ||
            Object.keys(wordText).length > 0) && (
            <button
              className="buttonitoReverse"
              
              onClick={() => {
                clearFilters();
              }}
            >
              {" "}
              Clear Filters
              <ClearAllIcon id="clearBtn" />
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default FilterCategories;

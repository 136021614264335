import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PriceRange from "./PriceRange";
import "./ApplyFilters.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { TextField, InputAdornment } from "@mui/material";
import { CountryDropdown } from "react-country-region-selector";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid lightBlue",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

export default function ApplyFilters(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setCountry([]);
    setLocation("");
    setInterPriceRange([0, props.max]);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [mycountry, setCountry] = React.useState([]);

  const [location, setLocation] = React.useState("");

  const handleChange = (country) => {
    setCountry(country);
  };

  const handleChangeLocation = (e) => {
    setLocation(e.target.value);
  };

  const [interPriceRange, setInterPriceRange] = React.useState([0, props.max]);

  const applyFilters = () => {
    var filters = [];
    filters.push(location);
    filters.push(mycountry);
    filters.push(interPriceRange);
    if (location !== "") {
      props.setLocation(location);
    }
    if (mycountry !== "") {
      props.setCountry(mycountry);
    }
    props.setPriceRange(interPriceRange);
    props.setSelectedFilters(filters);
    setOpen(false);
  };

  return (
    <div>
      <Button
        style={{
          padding: "10px 15px",
          fontFamily:
            '"Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif',
          borderRadius: "20px",
          border: "0 !important",
          outline: "0 !important",
          background: "teal",
          color: "white",
          cursor: "pointer",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          width: "350px",
          height: "45px",
        }}
        onClick={handleOpen}
      >
        Filters
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="outerDiv">
          <div class="modelfilter">
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Select One or Multiple Filters
            </Typography>
            <Typography
              variant="h6"
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Price Range{" "}
              <PriceRange max={props.max} setPriceRange={setInterPriceRange} />
            </Typography>
            <Typography
              variant="h6"
              id="modal-modal-description"
              sx={{ mt: 1 }}
            >
              Country
            </Typography>
            <CountryDropdown
              className="inputfilter"
              value={mycountry}
              onChange={(e) => handleChange(e)}
            />
            <br />
            <Typography
              variant="h6"
              id="modal-modal-description"
              sx={{ mt: 2 }}
            >
              Location
            </Typography>
            <input
              type="text"
              className="inputfilter"
              onChange={(e) => handleChangeLocation(e)}
            />

            <br />
            <br />
            <button
              className="formt-btn"
              onClick={() => {
                applyFilters();
              }}
            >
              Apply Filters
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Gem1 from "../../assets/gem_1.jpg";
import Gem2 from "../../assets/gem_2.jpg";
import Gem3 from "../../assets/gem_3.jpg";
import "animate.css";
import "./Card_1.css"; // Import your custom CSS for fading effect

const Card = () => {
  return (
    <div className="mt-4 px-4">
      <div className="relative overflow-x-hidden">
        <Carousel
          showThumbs={false}
          showStatus={false}
          infiniteLoop
          autoPlay
          interval={5000}
          transitionTime={800}
        >
          <div>
            <img
              src={Gem1}
              alt="cover1"
              className="carousel-image fade"  // Apply the custom CSS class here
            />
          </div>
          <div>
            <img
              src={Gem2}
              alt="cover2"
              className="carousel-image fade"  // Apply the custom CSS class here
            />
          </div>
          <div>
            <img
              src={Gem3}
              alt="cover3"
              className="carousel-image fade"  // Apply the custom CSS class here
            />
          </div>
        </Carousel>
        {/* Dark overlay */}
        <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        <div className="absolute inset-0 flex flex-col items-center lg:items-start lg:gap-8 p-4 lg:p-10 justify-center">
          {/* Section for text */}
        </div>
      </div>
    </div>
  );
};

export default Card;

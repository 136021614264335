import React from 'react';
import Slider from "react-slick";
import img1 from '../../assets/gem-new4.jpg'
import img2 from '../../assets/gem-new1.jpg';
import img3 from '../../assets/gem-new2.jpg';
import img4 from "../../assets/gem-new3.jpg";
import img5 from '../../assets/gem-new5.jpg';

const images = [img1, img2, img3, img4 , img5]; // reuse the same image for each slide

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,  // Display 5 images in a row
    slidesToScroll: 1, // Scroll 1 image at a time
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Adjust for medium screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600, // Adjust for small screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480, // Adjust for very small screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const cardStyle = {
    transition: "transform 0.5s ease", // Smooth transition for hover effect
    transitionDelay: "0.1s", // Delay before hover effect starts
  };

  const cardHoverStyle = {
    transform: "scale(1.05)", // Scale up the card slightly on hover
  };

  return (
    <div style={{ width: "80%", margin: "auto", padding: "40px 0" }}>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            style={cardStyle}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.transitionDelay = "0.1s"; // Delay when the hover starts
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.transitionDelay = "0"; // No delay when returning to normal
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{ width: "100%", height: "auto", borderRadius: "10px" }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default Carousel;

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../components/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { IoLocationSharp } from "react-icons/io5";

import "./Dashboard.css";

function Dashboard() {
  const { authState } = useContext(AuthContext);
  let navigate = useNavigate();
  let id = authState.id;
  const [userInfo, setUserInfo] = useState({});
  const [myItems, setMyItems] = useState([]);
  const [myPastItems, setMyPastItems] = useState([]);
  const [myWatchlist, setMyWatchlist] = useState([]);
  const [gotMail, setGotMail] = useState(false);

  useEffect(() => {
    axios
      .get(`https://backgem.tfdatamaster.com/auth/fetchyall/${id}`)
      .then((response) => {
        if (response.data.message) {
          console.log(response.data.message);
          navigate("/");
        } else {
          setUserInfo(response.data);

          // check if they have mail
          axios
            .get(`https://backgem.tfdatamaster.com/mail/newmail/${id}`)
            .then((respi) => {
              if (respi.data.gotmail === true) {
                setGotMail(true);
              }
            })
            .catch((error) => { });

          axios
            .get(`https://backgem.tfdatamaster.com/items/fetchByUser/${id}`)
            .then((res) => {
              const available = res.data.filter((value) => {
                return (
                  value.state === "EXPECTED" || value.state === "AVAILABLE"
                );
              });
              const past = res.data.filter((value) => {
                return value.state === "PURCHASED" || value.state === "EXPIRED";
              });

              setMyItems(available);
              setMyPastItems(past);
            })
            .catch((error) => {
              navigate("/");
            });

          axios
            .get(`https://backgem.tfdatamaster.com/items/mywatchlist/${id}`)
            .then((resbidy) => {
              setMyWatchlist(resbidy.data);
            })
            .catch((error) => {
              navigate("/");
            });
        }
      })
      .catch((error) => {
        console.log(error);
        navigate("/");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gotMail]);
  // Pagination Information
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 6;
  const visitedPages = pageNumber * itemsPerPage;
  const pageCount = Math.ceil(myItems.length / itemsPerPage);
  // Displaying the items of this particular page
  const displayItems = myItems
    .slice(visitedPages, visitedPages + itemsPerPage)
    .map((value, key) => {
      return (
        <div
          className="Auctioniitem" // Use your CSS class names here for styling
          onClick={() => {
            navigate(`/item/${value.id}`);
          }}
        >
          <div className="name">{value.name} </div>
          <div className="Auction-body">
            <img
              className="lando_image"
              src={value.coverPhoto}
              alt="coverphoto"
            />
          </div>
 
          {/* <div className="footer" style={{}}>
            <div style={{ display: "flex", justifyItems: "center", gap: "5px" }}>
              <div> <IoLocationSharp style={{ color: "white" }} /></div>

              <div><p style={{ color: "white", fontSize: "20px", fontWeight: "1000" }}>  {value.location}, {value.country}</p></div>
            </div>
            <div style={{ color: "white", fontSize: "20px", fontWeight: "1000" }}>Rs {value.currently}</div>
          </div> */}
        </div>
      );
    });
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="dashboard">
      <div className="profile-header"></div>
      <div className="userprofile" style={{ marginTop: "40px" }}>
        <br />
        <div className="firstnameandlastname">
          <div class="profile-photo"></div>{" "}
          <h1 style={{ fontSize: "35px", color: "black" }} className="nameid">{`${userInfo.name} ${userInfo.surname}`}</h1>

          <h2 className="location">{`${userInfo.location}, ${userInfo.country}`}</h2>
        </div>
        <div className="rating-container">
          <div className="rating">
            <div className="ratingIcon">
              Seller Rating: {userInfo.sellerRating}
            </div>
            <div className="ratingValue">
              {userInfo.saleCount ? (
                <>Average: {userInfo.sellerRating / userInfo.saleCount}</>
              ) : (
                <>&nbsp;No Sales Yet</>
              )}
            </div>
          </div>
          <div className="rating">
            <div className="ratingIcon">
              Bidder Rating: {userInfo.bidderRating}
            </div>
            <div className="ratingValue">
              {userInfo.buyCount ? (
                <>Average: {userInfo.bidderRating / userInfo.buyCount}</>
              ) : (
                <>&nbsp;No Purchases Yet</>
              )}
            </div>
          </div>
        </div>

        <div className="gridItemRight">
          <Link className="mailLink" to="/mail">
            <button className="Allbutton">You've Got Mail!</button>
          </Link>
          <br />
          <Link className="mailLink" to="/mail">
            <button className=" Allbutton">All Caught Up!</button>
          </Link>
        </div>
      </div>
      <br />
      <br />{" "}
      <div className="pauction" style={{ marginTop: "180px" }}>
        <div className="informationSection">
          <h2  style={{fontSize: "35px" , color: "black"}}>Active Auctions</h2>
          {myItems.length !== 0 ? (
            <div className="container">
              <h3 text="The catalogue of your current listings" />
            </div>
          ) : (
            <div className="container">
              <h3 text="No current listings, start selling by clicking below" />
            </div>
          )}
        </div>
        {myItems.length !== 0 && (
          <>
            <div className="auction-title1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <p style={{ backgroundColor: "black", padding: "8px", color: "white", width: "280px", borderRadius: "20px", textAlign: "center" }}>
                {userInfo.username}'s Auctions{" "}
              </p>
            </div>

            <br />
            <div className="grid-itemicontainerr">{displayItems}</div>
            <div className="pagenavigation">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationButtons"}
                previousLinkClassName={"previousButton"}
                nextLinkClassName={"nextButton"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default Dashboard;
